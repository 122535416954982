import { defineStore } from 'pinia'
import { useAPI } from '@/composables/api'
import { useCognitoStore } from './cognito'
import { useAnalytics } from '@/composables/analytics.js'
import { useServerError } from '@/composables/serverError'
import * as Sentry from '@sentry/vue'

export const useCurrentUserStore = defineStore('currentUser', {
    state: () => {
        return {
            id: null,
            email: null,
            full_name: null,
            account_id: null,
            account_name: null,
            products: null,
            contracts_enabled: null,
            analytics_enabled: null,
            dashboards_enabled: null,
            dashboard_allowed: null,
            dashboards_config: null,
            apis_config: null,
            region_count: null,
        }
    },
    getters: {
        cognito() {
            return useCognitoStore()
        },
        userId() {
            // June.so doesn't ping us for anonymous user events; an ID has to be supplied. This forces us to create an ID at random
            // for events like user confirmation failure where they don't have a DB ID, just so we can get the event in slack.
            if (this.id) return this.id

            this.$patch({ id: crypto.randomUUID() })

            return this.id
        },
        isAdmin() {
            return this.cognito.isAdmin
        },
        has: (state) => {
            return {
                signal: Boolean(state.products?.signal),
                amplitude: Boolean(state.products?.amplitude),
                marketShare: state.products?.marketShare,
                customReports: state.products?.signal?.config?.sigmaReports,
            }
        },
        customReports: (state) => {
            if (!state.products?.signal?.config?.sigmaReports) return null

            const reports = Object.entries(state.products.signal.config.sigmaReports)

            return reports.map(([key, embed]) => {
                return {
                    key,
                    embed,
                    param: new URL(embed).pathname.split('/').pop(),
                }
            })
        },
        dashboards: (state) => state.dashboards_config,
        dashboard: (state) => (dashboard) => state.dashboards_config?.find((c) => c.dashboard === dashboard),
        apis: (state) => state.apis_config,
        isApisProduct() {
            return Boolean(this.apis?.length)
        },
        isDasboardsMenuConfigured() {
            return this.dashboards?.length || this.dashboards_enabled
        },
        isDashboardConfigured() {
            return (dashboard) => this.dashboards?.some((c) => c.dashboard === dashboard)
        },
        defaultDashboard() {
            if (this.dashboards?.length && this.dashboards[0]) {
                return this.dashboards[0].dashboard
            }

            if (this.dashboard_allowed) {
                return this.dashboard_allowed
            }

            return null
        },
    },
    actions: {
        async authenticate() {
            return await this.cognito.authenticate()
        },
        async fetchAccountDetails() {
            const { dashboardAPI } = useAPI()

            let prefs = null
            try {
                prefs = await dashboardAPI('/users/prefs')
            } catch (e) {
                await this.resetUser()
                const { getMessage } = useServerError()

                const message = getMessage(e)

                throw new Error(message)
            }

            if (!prefs) {
                await this.resetUser()

                throw new Error('User Prefs Failed')
            }

            const { id, email, full_name, account, products } = prefs

            this.$patch({
                id,
                email,
                full_name,
                account_id: account.id,
                account_name: account.name,
                products,
                contracts_enabled: account.contracts_enabled,
                analytics_enabled: account.analytics_enabled,
                dashboards_enabled: account.dashboards_enabled,
                dashboard_allowed: account.dashboard_allowed,
                dashboards_config: account.dashboards_config,
                apis_config: account.apis_config,
                region_count: account.region_count,
            })

            const { analytics } = useAnalytics()

            analytics.identify(this.userId, {
                email,
                name: full_name,
                account: account.name,
            })

            await this.cognito.setAccountAttribute(account.id)

            Sentry.setUser({ id: this.id, email: this.email })
        },

        async signUp({ email, password, registrationKey, fullName }) {
            let confirmedUsername

            // attempt to create user in cognito
            // if user exists allow it to pass and try to create in our db
            try {
                confirmedUsername = await this.cognito.signUp({
                    email,
                    password,
                    registrationKey,
                    fullName,
                })
            } catch (err) {
                if (err.code !== 'UsernameExistsException') {
                    throw new Error(err.message)
                }
            }

            const { dashboardAPI } = useAPI()

            const user = await dashboardAPI('/users', {
                method: 'POST',
                body: {
                    email: confirmedUsername,
                    fullName,
                    regKey: registrationKey,
                },
            })

            this.$patch({
                full_name: fullName,
            })

            const { analytics } = useAnalytics()

            analytics.track({
                userId: this.userId,
                event: 'User Signed Up',
                properties: {
                    regKey: registrationKey,
                    email: confirmedUsername,
                    fullName: fullName,
                },
            })

            return user
        },
        async signIn(payload) {
            await this.cognito.signIn(payload)

            this.$patch({
                email: payload.email,
            })

            const { analytics } = useAnalytics()

            analytics.track({
                userId: this.userId,
                event: 'User Signed In',
                properties: {
                    email: payload.email,
                },
            })

            return
        },
        async signOut() {
            await this.resetUser()

            const { analytics } = useAnalytics()

            analytics.track({
                userId: this.userId,
                event: 'User Signed Out',
                properties: {
                    email: this.email,
                    full_name: this.full_name,
                    account: this.account_name,
                },
            })

            return
        },
        async confirmUser({ confirmationCode, email }) {
            const confirmedUsername = await this.cognito.confirmUser({
                confirmationCode,
                email,
            })

            const { dashboardAPI } = useAPI()

            await dashboardAPI('/users/confirm', {
                method: 'POST',
                body: {
                    email: confirmedUsername,
                },
            })

            const { analytics } = useAnalytics()
            analytics.track({
                event: 'User Confirmed',
                properties: {
                    email: email,
                },
            })

            return confirmedUsername
        },
        async forgotPassword(payload) {
            const { analytics } = useAnalytics()

            await this.cognito.forgotPassword(payload)

            analytics.track({
                userId: this.userId,
                event: 'User Password Forgot',
                properties: {
                    email: payload.email,
                },
            })

            return
        },
        async resetPassword(payload) {
            const { analytics } = useAnalytics()

            await this.cognito.resetPassword(payload)

            analytics.track({
                userId: this.userId,
                event: 'User Password Reset',
                properties: {
                    email: payload.email,
                },
            })

            return
        },
        token() {
            return this.cognito.token()
        },
        accessToken() {
            return this.cognito.accessToken()
        },
        async resetUser() {
            await this.cognito.signOut()

            this.$reset()
        },
    },
})
